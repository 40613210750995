import React from 'react';
import Fade from 'react-reveal/Fade';

import './Features.css';

import featuresImg from '../../../assets/images/svg/featuresImg.svg'

function Features() {
    return (
        <div className="features">
            <div className="features-body">
                <div className="features-description">
                    <Fade left>
                        <div className="features-text">
                            <h2>Plantillas profesionales</h2>
                            <p>Elija una de nuestras muchas plantillas atractivas, complete sus habilidades y experiencias y estará listo para comenzar.  Nuestros diseños completamente personalizables le permiten cambiar la fuente, los colores, el diseño y las imágenes, convirtiéndolo en un reflejo de usted, con gran facilidad.</p>
                        </div>
                    </Fade>

                    <Fade left>
                        <div className="features-text">
                            <h2>Dirección de Empleo MGA</h2>
                            <p>
                            Cree su currículum sin las molestias habituales, la Dirección de enpleo buscará mach entre la peticiones de empleo de los empleadores.
                            </p>
                        </div>
                    </Fade>                     

                </div>
                <Fade right>
                    <div className="features-img">
                        <img src = {featuresImg} alt="IMAGEN"/>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Features
