import { Button } from '@material-ui/core'
import React from 'react';
import { useHistory } from "react-router-dom";

import './Main.css';

import mainImg from '../../../assets/images/svg/landingImg.svg';
import logo from '../../../assets/images/png/logo.png';


function Main() {
  let history = useHistory();

  function handleClick() {
    history.push("/create");
  }
  
    return (
        <div className="main">
            <img src={logo} alt="MGA"/>
            <div className="main-container">
                <div className="main-description">
                    <h1>La herramienta definitiva para un currículum profesional.</h1>
                    <p>MGA gastión que avanza </p>
                    <Button onClick={handleClick}>Crea tu CV</Button>
                </div>
                <div className="img-container">
                    <img src={mainImg} alt="LOGO GRAFICO"/>
                </div>
            </div>  
                       
        </div>
    )
}

export default Main
